import React, { useState } from "react";
import { Link, navigate } from "gatsby";

import Dropdown from "@accrosoft-ltd/accro-multi-level-dropdown";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Navbar = ({ props, websiteNavData }) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const ReturnChildNavItems = ({ WebsiteNavData }) => {
    return (
      <>
        {WebsiteNavData && WebsiteNavData.length > 0 ? (
          WebsiteNavData.map((item, i) => (
            <Dropdown
              menuClassName="dropdown-child-nav"
              wrapperClassName="dropdown-wrapper-nav"
              buttonClassName="dropdown-parentLink link-item"
              className="dropdown-child-nav"
              isActive={true}
              title={item.title}
              onClick={() =>
                navigate(
                  item.parentSlug ? item.parentSlug + item.slug : item.slug
                )
              }
            >
              <RenderChildLinks item={item} i={i} />
            </Dropdown>
          ))
        ) : (
          <li className="loading-nav"></li>
        )}
      </>
    );
  };

  const RenderChildLinks = ({ item }) => {
    return (
      <>
        {item.children && item.children.length > 0 && (
          <>
            {item.children.map((child, i) => (
              <RenderChildLinkHTML child={child} />
            ))}
          </>
        )}
      </>
    );
  };

  const RenderChildLinkHTML = ({ child, i }) => {
    return (
      <Dropdown.Item
        className="dropdown-item link"
        key={i}
        onClick={() =>
          navigate(
            child.parentSlug ? child.parentSlug + child.slug : child.slug
          )
        }
      >
        {child.children && <span className="fas fa-angle-right"></span>}
        <div>
          <span>{child.title}</span>
        </div>

        {child.children && child.children.length > 0 && (
          <Dropdown.Submenu
            menuClassName="dropdown-submenu"
            className="dropdown-submenu"
          >
            {child.children.map((childtwo, itwo) => (
              <React.Fragment key={i + "_" + itwo}>
                <RenderChildLinkHTML child={childtwo} i={itwo} />
              </React.Fragment>
            ))}
          </Dropdown.Submenu>
        )}
      </Dropdown.Item>
    );
  };

  return (
    <div className="navbar top-links__wrapper py-2 d-flex">
      <div className="container d-flex justify-content-between top-links__wrapper__container">
        {props.siteConfig.companyCareersSite ? (
          <a href={`https://www.sequani.com/`} className="navbar-brand d-flex">
            {props.smartfeedCompanyData &&
              props.smartfeedCompanyData.CompanyName &&
              props.smartfeedCompanyData.Logo && (
                <img
                  className="logo"
                  loading="lazy"
                  src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/06febb54-9293-4f03-b839-e1078b36cf74_full_logo.png"
                  alt="sequani non clinical logo"
                />
              )}
          </a>
        ) : (
          <a
            href={`${
              props.useGroupVacsLink === true
                ? "/group-vacancies/"
                : `/vacancies/`
            }${props.apiKey}/`}
            className="navbar-brand"
          >
            {props.smartfeedCompanyData &&
            props.smartfeedCompanyData.CompanyName &&
            props.smartfeedCompanyData.Logo ? (
              <img
                loading="lazy"
                src={props.smartfeedCompanyData.Logo}
                className="logo"
                alt={props.smartfeedCompanyData.CompanyName}
              />
            ) : (
              <img
                loading="lazy"
                src="https://logos.vacancy-filler.co.uk/Custom%20Test/SmartFeedDemo.svg"
                className="logo"
                alt="Vacancy Filler"
              />
            )}
          </a>
        )}

        <div className="nav-container d-flex">
          <ul className="d-none d-lg-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
            {props.siteConfig.showWebsiteNav && websiteNavData && (
              <ReturnChildNavItems WebsiteNavData={websiteNavData} />
            )}
            <li>
              <div className="link-item">
                <Link to="/">Home</Link>
              </div>
            </li>
            <li>
              <AnchorLink to="/#rolesAvailable" stripHash>
                Job Vacancies
              </AnchorLink>
            </li>
            {props.smartfeedCompanyData &&
              props.smartfeedCompanyData.EnabledModules &&
              props.smartfeedCompanyData.EnabledModules.BankRoles && (
                <li>
                  {props.siteConfig.companyCareersSite ? (
                    <Link
                      to={`${
                        props.useGroupVacsLink === true
                          ? "/group-vacancies/"
                          : `/vacancies/`
                      }?postingtype=Register`}
                    >
                      Register your Interest
                    </Link>
                  ) : (
                    <Link
                      to={`${
                        props.useGroupVacsLink === true
                          ? "/group-vacancies/"
                          : `/vacancies/`
                      }${props.apiKey}/?postingtype=Register`}
                    >
                      Register your Interest
                    </Link>
                  )}
                </li>
              )}
            <li>
              {props.siteConfig.companyCareersSite ? (
                <Link
                  to={`${
                    props.useGroupVacsLink === true
                      ? "/group-vacancies/"
                      : `/vacancies/`
                  }saved/`}
                >
                  Saved Jobs
                </Link>
              ) : (
                <Link
                  to={`${
                    props.useGroupVacsLink === true
                      ? "/group-vacancies/"
                      : `/vacancies/`
                  }${props.apiKey}/saved/`}
                >
                  Saved Jobs
                </Link>
              )}
            </li>
            <li>
              {props.siteConfig.companyCareersSite ? (
                <Link
                  to={
                    props.useGroupVacsLink === true
                      ? "/group-job-alerts/"
                      : `/job-alerts/`
                  }
                  className="nav-action-sm"
                  role="button"
                >
                  Get job alerts
                </Link>
              ) : (
                <Link
                  to={`${
                    props.useGroupVacsLink === true
                      ? "/group-job-alerts/"
                      : `/job-alerts/`
                  }${props.apiKey}/`}
                  className="nav-action-sm"
                  role="button"
                >
                  Get job alerts
                </Link>
              )}
            </li>
            {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
              <li>
                <a
                  href={props.appGatewayURL.ApplicantGateway}
                  target="_blank"
                  rel="noreferrer"
                >
                  Applicant Gateway
                </a>
              </li>
            )}
          </ul>

          <ul className="d-none d-lg-inline-flex mr-auto header__top-links header-bottom-links">
            <li className="parentLink">
              <span>About Us</span>
              <ul className="dropdown">
                <li>
                  <Link to="/equality-diversity-and-inclusion">
                    Equality, Diversity and Inclusion Statement
                  </Link>
                </li>
                <li>
                  <Link to="/life-at-sequani">Life at Sequani</Link>
                </li>
                <li>
                  <Link to="/featured-career">Working with Animals</Link>
                </li>
              </ul>
            </li>
            <li className="parentLink">
              <span>Employee Stories</span>
              <ul className="dropdown">
                <li>
                  <Link to="/employee-stories/alex">
                    Alex, Animal Technician - Laboratory Animal Sciences (LAS)
                  </Link>
                </li>
                <li>
                  <Link to="/employee-stories/fran">
                    Fran, Associate Toxicologist - Toxicology
                  </Link>
                </li>
                <li>
                  <Link to="/employee-stories/alex-1643027857">
                    Alex, Team Leader - Histology
                  </Link>
                </li>
                <li>
                  <Link to="/employee-stories/farzana">
                    Farzana, Senior Scientist - Bioanalysis
                  </Link>
                </li>
                <li>
                  <Link to="/employee-stories/ellen">
                    Ellen, Support Officer - Business Development
                  </Link>
                </li>              
              </ul>
            </li>
            <li>
              <Link to="/work-experience">Work Experience</Link>
            </li>
          </ul>

          <div className="d-lg-none nav__hamburger_wrap">
            <div
              className={`nav_hamburger_wrap__inner ${
                showMobileNav === true ? "toggled" : ""
              }`}
            >
              <div
                className="nav_hamburger_icon btn p-3"
                onClick={() => setShowMobileNav(!showMobileNav)}
                aria-hidden="true"
              >
                {!showMobileNav ? (
                  <span className="fas fa-bars"></span>
                ) : (
                  <span className="fas fa-times"></span>
                )}
              </div>
              <div className="nav_hamburger_wrap">
                <ul className="navigation">
                  {props.siteConfig.showWebsiteNav && websiteNavData && (
                    <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                  )}
                  <li>
                    <div className="link-item">
                      <Link to="/">Home</Link>
                    </div>
                  </li>
                  <li className="parentLink">
                    <div className="link-item">
                      <span>About Us</span>
                    </div>
                  </li>
                  <ul className="dropdown">
                    <li>
                      <Link to="/equality-diversity-and-inclusion">
                        Equality, Diversity and Inclusion Statement
                      </Link>
                    </li>
                    <li>
                      <Link to="/life-at-sequani">Life at Sequani</Link>
                    </li>
                    <li>
                      <Link to="/featured-career">Working with Animals</Link>
                    </li>
                  </ul>
                  <li className="parentLink">
                    <div className="link-item">
                      <span>Employee Stories</span>
                    </div>
                  </li>
                  <ul className="dropdown">
                    <li>
                      <div className="link-item">
                        <Link to="/employee-stories/alex">
                          Alex, Animal Technician - Laboratory Animal Sciences
                          (LAS)
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <Link to="/employee-stories/fran">
                          Fran, Associate Toxicologist - Toxicology
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <Link to="/employee-stories/alex-1643027857">
                          Alex, Team Leader - Histology
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <Link to="/employee-stories/farzana">
                          Farzana, Senior Scientist - Bioanalysis
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <Link to="/employee-stories/ellen">Ellen</Link>
                      </div>
                    </li>                 
                  </ul>
                  <li>
                    <div className="link-item">
                      <Link to="/work-experience">Work Experience</Link>
                    </div>
                  </li>
                  <li>
                    <div className="link-item">
                      <Link to="/vacancies">Job Vacancies</Link>
                    </div>
                  </li>
                  {props.smartfeedCompanyData &&
                    props.smartfeedCompanyData.EnabledModules &&
                    props.smartfeedCompanyData.EnabledModules.BankRoles && (
                      <li>
                        <div className="link-item">
                          {props.siteConfig.companyCareersSite ? (
                            <Link
                              to={`${
                                props.useGroupVacsLink === true
                                  ? "/group-vacancies/"
                                  : `/vacancies/`
                              }?postingtype=Register`}
                            >
                              Register your Interest
                            </Link>
                          ) : (
                            <Link
                              to={`${
                                props.useGroupVacsLink === true
                                  ? "/group-vacancies/"
                                  : `/vacancies/`
                              }${props.apiKey}/?postingtype=Register`}
                            >
                              Register your Interest
                            </Link>
                          )}
                        </div>
                      </li>
                    )}
                  {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                    <li>
                      <div className="link-item">
                        <a
                          href={props.appGatewayURL.ApplicantGateway}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Applicant Gateway
                        </a>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="link-item">
                      {props.siteConfig.companyCareersSite ? (
                        <Link
                          to={`${
                            props.useGroupVacsLink === true
                              ? "/group-vacancies/"
                              : `/vacancies/`
                          }saved/`}
                        >
                          Saved Jobs
                        </Link>
                      ) : (
                        <Link
                          to={`${
                            props.useGroupVacsLink === true
                              ? "/group-vacancies/"
                              : `/vacancies/`
                          }${props.apiKey}/saved/`}
                        >
                          Saved Jobs
                        </Link>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-repeat"></div>
    </div>
  );
};

export default Navbar;
