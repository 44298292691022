import {Link} from "gatsby";
import React from "react";

const Footer = (props) => (
  <footer className="footer-container">
    {props.smartfeedCompanyData &&
      props.smartfeedCompanyData.CompanyName &&
      props.smartfeedCompanyData.CompanyName !== "DefaultCompany" && (
        <div className="footer-register-wrap">
          {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
            <div className="footer-register-container container">
              <div className="vf-careers-site-spacing-cta">
                <article aria-label="Subscribe to job alerts">
                  <div className="ctacard soft">
                    <div className="ctacard__body d-flex justify-content-center">
                      <div className="col-md-10 col-lg-6 text-center">
                        <h4 className="ctacard__body__title">
                          <span>Can't find a job role to suit you?</span>
                        </h4>
                        <p className="ctacard__body__summary">
                          Get job alerts straight to your inbox and be the first
                          to see our new vacancies.
                        </p>
                        <Link
                          to={
                            !props.siteConfig.companyCareersSite
                              ? `${
                                  props.useGroupVacsLink === true
                                    ? "/group-job-alerts/"
                                    : `/job-alerts/`
                                }${props.apiKey}`
                              : props.useGroupVacsLink === true
                              ? "/group-job-alerts/"
                              : `/job-alerts/`
                          }
                          className="btn--action"
                        >
                          Subscribe to job alerts
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )}
        </div>
      )}
    <div className="slogan-container">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="slogan">great people, great work, real results</div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-attribution-wrap">
      <div className="footer-attribution-container">
        <div className="container footer-top">
          <div className="row">
            <div className="col-md-2">
              <div className="span3 contacts">
                <p>
                  <span className="title">HOW CAN WE HELP?</span>
                  <br />
                  <strong>Tel.: </strong>
                  <span>+44 1531 634121</span>
                  <br />
                  <strong>Fax: </strong>
                  <span>+44 1531 634753</span>
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="span4 contacts">
                <strong>Sequani Limited,</strong>
                <br />
                <span>Bromyard Road, Ledbury</span>
                <br />
                <span>HR8 1LH United Kingdom</span>
                <br />
              </div>
            </div>
            <div className="col-md-2">
              <a
                href="https://www.sequani.com/Contact.aspx"
                className="btn"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
            <div className="col-md-3">
              <div className="span5">
                <img
                  src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/31d0b1c6-8f67-4a2f-bb0a-97520d8b05b1_full_logo-footer.png"
                  alt="Sequani non clinical"
                  className="pull-right"
                />
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>

        <div className="container footer-bottom">
          <div className="row">
            <div className="col-12 col-md-6 left text-center text-lg-left text-md-left">
              {!props.siteConfig.companyCareersSite && (
                <span>
                  Copyright © {new Date().getFullYear()} |{" "}
                  <a
                    rel="noopener"
                    target="noopener noreferrer"
                    href={`https://www.vacancyfiller.com/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                      props.smartfeedCompanyData &&
                      props.smartfeedCompanyData.CompanyName
                        ? props.smartfeedCompanyData.CompanyName
                        : props.apiKey
                        ? props.apiKey
                        : "noidavailable"
                    }`}
                  >
                    Powered Vacancy Filler
                  </a>
                </span>
              )}
              <div className="accessibility-selectors">
                <ul>
                  <li className="fontSize_Selection">
                    <button
                      className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                      onClick={() => props.setFontSize("normal")}
                    >
                      <span className="fal fa-font"></span>
                    </button>
                    <button
                      className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                      onClick={() => props.setFontSize("medium")}
                    >
                      <span className="fal fa-font"></span>
                    </button>
                    <button
                      className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                      onClick={() => props.setFontSize("large")}
                    >
                      <span className="fal fa-font"></span>
                    </button>
                  </li>
                  <li className="contrast_Selection">
                    {props.colourContrast ? (
                      <button
                        className="contrast_trigger contrast_trigger_light"
                        onClick={() => props.setColourContrast(false)}
                      >
                        <span className="fas fa-adjust"></span>
                      </button>
                    ) : (
                      <button
                        className="contrast_trigger contrast_trigger_dark"
                        onClick={() => props.setColourContrast(true)}
                      >
                        <span className="fad fa-adjust"></span>
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-6 right text-center text-lg-right">
              <div>
                <a
                  href="https://trustsealinfo.websecurity.norton.com/splash?form_file=fdf/splash.fdf&amp;dn=www.sequani.com&amp;lang=en"
                  target="_blank"
                >
                  SSL Certificate Status
                </a>
                <span>|</span>
                <a
                  href="https://sequani.com/Detail.aspx?page=terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>
                <span>|</span>
                <a
                  href="https://sequani.com/Detail.aspx?page=privacy"
                  target="_blank"
                >
                  Privacy
                </a>
                <span>|</span>
                {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                  <>
                    <a
                      href={props.appGatewayURL.ApplicantGateway}
                      target="_blank"
                      rel="noreferrer"
                      className="appliant-gateway"
                    >
                      Applicant Gateway
                    </a>
                    {!props.siteConfig.companyCareersSite && <span> | </span>}
                  </>
                )}
                {!props.siteConfig.companyCareersSite && (
                  <>
                    <a
                      href={`https://www.vacancyfiller.com/privacy-policy/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                        props.apiKey ? props.apiKey : "noidavailable"
                      }`}
                      rel="noopener"
                      target="noopener noreferrer"
                    >
                      Vacancy Filler Privacy Policy
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
